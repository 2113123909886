*{
    padding: 0px;
    margin: 0px;
}

.admin-btn{
    background: #00365E !important;
    color: #EDCD1F !important;
}

footer p{
    font-size: 1.2rem;
    color: #00365E;
    font-weight: bold;
}