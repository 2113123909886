nav .nav-link {
    color: white;
}
nav img {
    height: 70px !important;
    width: 250px;
}
.nav-part-2 .nav-link {
    font-size: 1.5rem;
    font-weight: light;
    color: white !important;
}
.nav-part-2 .active {
    color: #EDCD1F !important;
}
nav button {
    border: 0px !important;
}
#off-nav-btn {
    height: 40px !important;
    width: 50px;
}
#offNav {
    height: 50px;
    width: 50px;
    background: transparent;
    border: 0px;
}
.offcanvas .nav-link {
    font-family: monaco;
    word-spacing: 2px;
    letter-spacing: 1px;
    color: white;
    font-size: 1.5rem;
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: transparent;
    z-index: 1000;
}
.nav-part-2 {
    background-color: #00365E;
}
@media screen and (max-width: 576px) {
    .nav-brand {
        background: transparent !important;
    }
}
@media screen and (max-width: 992px) and (min-width: 576px) {
    nav img {
        width: 250px;
        height: 70px;
    }
    .offcanvas .nav-link {
        font-size: 25px !important;
    }
    .nav-brand {
        background: transparent !important;
    }
}
@media screen and (max-width: 576px) {
    nav img {
        width: 220px; /* 150px */
        height: 30px;
    }
}
@media screen and (min-width: 992px) {
    nav {
        background: linear-gradient(to right, #EDCD1F 50%, #00345c 50%);
    }
}
