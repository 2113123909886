

.ourMission {
    position: relative;
    background:  #eef0f2;
}

.our-mission-content {
    display: flex;
    flex-direction: column;
}

.ourMission .container {
    position: relative;
    z-index: 2;
}

.ourMission .background-image {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 1;
}

@media (max-width: 991.98px) {
    .ourMission .background-image {
        display: none;
    }
}

@media (min-width: 992px) {
    .our-mission-content .container {
        flex-direction: row;
    }
    .ourMission .background-image {
        display: block;
    }
}

.our-mission-title {
    font-size: 2.5rem;
    color: #00365E;
}

.ourMissionLine {
    height: 5px;
    width: 100px;
    background-color: #FFD700;
    margin-bottom: 1rem;
}

.fw-light {
    font-weight: 300;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}

@media (max-width: 575.98px) {
    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
}
