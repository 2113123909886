

  /* General styling */
.DisplayProjects h2 {
  color: #EDCD1F !important;
  font-weight: light;
}

.DisplayProjects h1 {
  color: #00365E !important;
  font-weight: bold;
}

/* Project card styling */
.projectsCard img {
  max-height: 200px; /* Fixed height for images */
  object-fit: cover; /* Maintain aspect ratio */
}

.adminPanelProjects .card-body {
  overflow-y: auto; /* Enable vertical scrolling */
}

.adminPanelProjects .form-control {
  height: auto;
  font-size: 0.8rem;
  width: 100%;
}

.adminPanelProjects .btn {
  margin-top: 10px;
}

/* Lightbox styling */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.lightbox-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
}

.lightbox-content img {
  width: 100%;
  height: auto;
}

.lightbox .close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 3rem;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 992px) {
  .lightbox-content {
    flex-direction: column;
  }

  .lightbox-left, .lightbox-right {
    width: 100%;
  }

  .lightbox-right {
    margin-top: 20px;
  }
}

#Edit{
  background: #00365e;
  color: #edcd1f;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
}



.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto; /* Center the loader */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.adminPanelProjects .form-check-input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  /* Customize appearance */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

.adminPanelProjects .form-check-input:checked {
  background-color: #007bff; /* Custom checked background color */
  border-color: #007bff; /* Custom checked border color */
}

.adminPanelProjects .form-check-input:focus {
  box-shadow: 0 0 0 1px #007bff; /* Custom focus outline color */
}