

   .DisplayProjects .carousel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .DisplayProjects .carousel-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .DisplayProjects .carousel-left,
  .DisplayProjects .carousel-right {
    flex: 1;
  }
  
  .DisplayProjects .carousel-left {
    margin-right: 20px;
  }
  
  .DisplayProjects .carousel-img {
    max-width: 100%;
    height: auto;
  }
  
  .DisplayProjects .close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
  }
  
  @media (max-width: 992px) {
    .DisplayProjects .carousel-content {
      flex-direction: column;
      max-height: 100%;
    }
  
    .DisplayProjects .carousel-left {
      margin-right: 0;
      margin-bottom: 20px;
      height: 50%;
    }
  
    .DisplayProjects .carousel-right {
      height: 50%;
      overflow-y: auto;
    }
  
    .DisplayProjects .carousel-img {
      height: 100%;
      object-fit: cover;
    }
  }
  
  .DisplayProjects .carousel-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    z-index: 1000;
  }
  
  .DisplayProjects .carousel-btn.left {
    left: 10px;
  }
  
  .DisplayProjects .carousel-btn.right {
    right: 10px;
  }
  
  .DisplayProjects h2 {
    margin-bottom: 10px;
  }
  
  .DisplayProjects p {
    font-size: 1rem;
    color: #333;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 2rem;
    color: #000;
    cursor: pointer;
  }
  

  @media screen and (min-width >= 992px) {
    .carousel-left img{
      height:100% !important;
    } 
  }

  .DisplayProjects .image-content {
    font-weight: bold;
    font-size: 1.4rem;
    color: #00365E !important;
    font-family: arial !important /* cursive */;
  }
  
  .DisplayProjects hr {
    height: 2px;
  }

  .DisplayProjects h2 {
    color: #EDCD1F !important;
    font-weight: light;
  }
  
  .DisplayProjects h1 {
    color: #00365E !important;
    font-weight: bold;
  }

  /* Spinner Styles */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust based on your layout needs */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white !important;}

.spinner {
  border: 20px solid #EDCD1F;
  border-left: 20px solid #00365E;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.classHeightForMobile{
      height:300px;
}

@media screen and (min-width:768px){
   .classHeightForMobile{
      height:500px !important;
   }
}
