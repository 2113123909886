/* Spinner Styles */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height, adjust as needed */
    z-index: 999;
    background-color: white !important;
}

.spinner {
    border: 20px solid #EDCD1F;
    border-left: 20px solid #00365E;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
}

/* Keyframes for spinning animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
