/* Header Styles */
header h1, header h2, header h3 {
    color: white;
    font-weight: bolder;
    text-align: center;
}

header h1 {
    font-size: 3.5rem;
}

header h2 {
    font-size: 3rem;
}

header h3 {
    font-size: 2.5rem;
}

.header-down-arrow {
    animation: slide 3s infinite ease;
}

@keyframes slide {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@media screen and (max-width: 768px) {
    header h1 {
        font-size: 3rem;
    }

    header h2 {
        font-size: 2.5rem;
    }

    header h3 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 576px) {
    header h1 {
        font-size: 2rem;
        filter: brightness(0.9);
    }

    header h2 {
        font-size: 2rem;
        filter: brightness(0.9);
    }

    header h3 {
        font-size: 2rem;
        filter: brightness(0.9);
    }
    
    .d-md-none img {
        filter: brightness(80%);
    }
}

/* Additional styles moved from header.js */
.header {
    position: relative;
}

.header-video-container {
    overflow: hidden;
    height: 100vh;
}

.header-video {
    width: 100%;
    opacity: 0.8;
    object-fit: cover;
    height: 100%;
}

.header-image {
    width: 100%;
    opacity: 0.8;
    object-fit: cover;
    height: 100vh;
}

.header-image img {
    height: 100vh;
}

.header-content {
    padding-top: 100px;
    position: absolute;
    z-index: 10;
    background: transparent;
}

#Header{
    padding: 0px;
}
