* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  #Projects .container {
    padding: 3rem 0;
  }
  
  .projects-title {
    font-size: 2.5rem;
    color: #00365e;
  }
  
  .projectsLine {
    width: 100px !important; /* 150px */
    height: 5px; /* 10px */
    background: #edcd1f;
    margin: 0 12px;
  }
  
  
  .grayscale {
    /* opacity: 0.4; */
    transition: opacity 0.5s ease-in-out, transform 1s ease-in-out;
  }
  
  .grayscale:hover {
    opacity: 1;
    transform: scale(1.3);
  }
  
  .image-container {
    position: relative;
    display: inline-block;
    margin: 10px;
    overflow: hidden;
  }

  #MoreProjectsBtn {
    background: #00365e;
    color: #edcd1f;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
  }
  
  @media screen and (min-width:768px){
    .projectsLine {
        margin: 0 auto !important;
    }
  }

  
#Projects .row{
  
    margin-left: 0px !important;
    margin-right: 0px !important;
  
}

/* Spinner Styles */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust based on your layout needs */
  background-color: white !important;
}

.spinner {
  border: 20px solid #EDCD1F;
  border-left: 20px solid #00365E;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
